import React from 'react';

export default function agreement() {
  return (
    <>
      <h1 style={{ paddingTop: '80px', textAlign: 'center' }}>
        Vendor Agreement
      </h1>

      <div style={{ margin: '40px' }}>
        <p>
          <strong>VENDOR AGREEMENT FOR PROVISION OF E-COMMERCE SERVICES</strong>
        </p>
        <p>
          This agreement is made between vendor and Tripleaim software Ltd
          (herein referred to as Firm) having its address at Valley view office
          park, Parklands Nairobi.
        </p>
        <p>
          <strong>WHEREAS </strong>
          the Vendor is engaged in the business of
          manufacturing/importing/distributing/retailing pharmaceuticals and similar
          items.
        </p>
        <p>
          <strong>AND WHEREAS </strong>
          the Tripleaim software is the owner of
          E-Commerce Website by the name of
          {' '}
          <a href="http://www.afyabook.com">www.afyabook.com</a>
          {' '}
          wherein various
          medical products of different nature are marketed and sold using
          electronic medium more particularly through the e-commerce domain.
        </p>
        <p>
          <strong>AND WHEREAS </strong>
          the parties hereto have after mutual
          discussions have come to an agreement that the products of the Vendor
          will be marketed by
          {' '}
          <a href="http://www.afyabook.com">www.afyabook.com</a>
          {' '}
          on their
          ecommerce store.
        </p>
        <p>
          <strong>AND WHEREAS </strong>
          parties have agreed to reduce their terms
          in writing
        </p>
        <p>
          <strong>NOW THIS AGREEMENT WITNESSES AS UNDER</strong>
        </p>
        <ol>
          <li>
            <strong> Commencement, Term, Renewal</strong>
          </li>
        </ol>
        <p>
          1.1 The date of execution of this agreement shall be the commencement
          date and the agreement shall remain valid and binding until terminated
          by either party giving the other 30 days written notice.
        </p>
        <ol start="2">
          <li>
            <strong>
              {' '}
              Marketing Tools/Support, Products, Availability of products etc.
            </strong>
          </li>
        </ol>
        <p>
          2.1 The Vendor
          {' '}
          <strong>will</strong>
          {' '}
          keep informed at all times
          afyabook.com about the availability of the products in its inventory
          along with detailed specifications like Molecule name, strength per
          dose, formulation, manufacturer etc. as may be required of the
          product. Details and specification on Ads and top placement will be
          discussed on case by case.
        </p>
        <ol start="3">
          <li>
            <strong> Fees</strong>
          </li>
        </ol>
        <p>
          3.1 The platform as such shall charge a service fee on sales generated,
          and a subscription for marketing and data analytics services on
          {' '}
          {' '}
          <a href="http://www.afyabook.com">www.afyabook.com</a>
          , The fees to be
          charged on Ads and top placement will be discussed case by case.
        </p>
        <p>
          3.2 It is expressly agreed by the parties hereto that the fees shall
          be paid upon subscription.
        </p>
        <ol start="4">
          <li>
            <strong> Order, Handling, Delivery etc.</strong>
          </li>
        </ol>
        <p>
          4.1 Orders for the product shall be received using the website
          {' '}
          <a href="http://www.afyabook.com">www.afyabook.com</a>
          {' '}
          and shall be
          forwarded to the Vendor by the Firm via email or application.
        </p>
        <p>
          4.2 The Vendor shall upon receipt of the order from the Firm
          immediately contact the customer and arrange to deliver the products
          to the designated address upon agreed payment plans with the customer
          as early as possible.
        </p>
        <p>
          4.3 The Vendor shall ensure that the products dispatched are of the
          specifications ordered and there is no variation whatsoever. The
          necessary guarantee/warranty shall be provided by the Vendor to the
          customer.
        </p>
        <p>
          4.4 The Vendor agrees to replace the defective products supplied to
          the customer at its own cost and shall not hold the Firm responsible
          in any manner whatsoever.
        </p>
        <ol start="5">
          <li>
            <strong> Covenants of Vendor</strong>
          </li>
        </ol>
        <p>The Vendor hereby covenants with the Firm as under:</p>
        <p>
          5.1 To deliver the product of the Ordered specifications/description
          only including quantity and quality prescribed in the Order and there
          should be no instance of wrong item being delivered and/or quality
          issue and/or issue of Non delivery. Further, the Vendor shall maintain
          adequate stock/inventory of the items at all times. In case the Vendor
          is running out of supplies or is likely not to fulfill the Order
          received by the Firm, it shall intimate to the Firm at least 48 hours
          (2 days) in advance so that notice of
          {' '}
          <strong>OUT OF STOCK </strong>
          for the product can be placed on the website.
        </p>
        <p>
          5.2 Not to send any kind of promotion material or any such material,
          which is, derogatory to and/or adverse to the interests financial or
          otherwise of the Firm, to the customer either along with the products
          supplied or in any manner whatsoever.
        </p>
        <p>
          5.3 Not to do any act/deal in a thing / products/goods/service which
          are either banned/prohibited by law or violates any of the
          intellectual property right of any party in respect of such product.
        </p>
        <p>
          5.4 The Vendor declares that it has all rights and authorizations in
          respect of intellectual property rights of third parties and is
          authorized to sale/provide/license such products to the customer. The
          copy of such authorization shall be provided on demand without failure
          and/or protest.
        </p>
        <p>
          5.5 The Vendor agrees to indemnify and keep indemnified the Firm from
          all claims/losses (including advocate fee for defending/prosecuting
          any case) that may arise against the Firm due to acts/omission on the
          part of the Vendor
        </p>
        <p>
          5.6 To provide to the Firm, for the purpose of the creation/display on
          website of Firm, the product description, images, disclaimer, delivery
          timelines, price and such other details for the products to be
          displayed and offered for sale.
        </p>
        <p>
          5.7 To ensure and not to
          {' '}
          <strong>provide</strong>
          {' '}
          any
          description/image/text/graphic which is unlawful, illegal,
          intimidating, obnoxious, objectionable, obscene, vulgar, opposed to
          public policy, prohibited by law or morality or is in violation of
          intellectual property rights including but not limited to Trademark
          and copyright of any third party or of inaccurate, false, incorrect,
          misleading description or is surrogate in nature. Further it will
          forward the product description and image only for the product which
          is offered for sale through the website of the Firm. The Vendor agrees
          that in case there is violation of this covenant, it shall do and
          cause to be done all such acts as are necessary to prevent disrepute
          being caused to the Firm
        </p>
        <p>
          5.8 To provide full, correct, accurate and true description of the
          product so as to enable the customers to make an informed decision.
          The Vendor agrees not to provide any such description/information
          regarding the product which amounts to misrepresentation to the
          customer.
        </p>
        <p>
          5.9 To be solely responsible for the quality, quantity,
          merchantability, guarantee, warranties in respect of the products
          offered for sale through portal of the Firm.
        </p>
        <p>
          5.10 At all times have access to the Internet and its email account to
          check the status of requested orders and will ensure prompt deliveries
          within the time frame agreed with the customer.
        </p>
        <p>
          5.11 To raise an invoice as well as receipt of payment in the name of
          Customer for an amount equivalent to the amount displayed as MRP on
          the online store to the customer and paid by/charged to the customer.
        </p>
        <p>
          5.12 Not to offer any Products for Sale on the Online Store, which are
          prohibited for sale, dangerous, against the public policy, banned,
          unlawful, illegal or prohibited under the Pharmacy and Poisons board
          laws.
        </p>
        <p>
          5.13 To provide satisfactory proof about the ownership/licenses of all
          the legal rights in the Products that are offered for sale on the
          Online Store as and when demanded by the Firm.
        </p>
        <p>
          5.14 To pass on the legal title, rights and ownership in the Products
          sold to the Customer.
        </p>
        <p>
          5.15 To be solely responsible for any dispute that may be raised by
          the customer relating to the goods, merchandise and services provided
          by the Vendor. No claim of whatsoever nature will be raised on the
          Firm.
        </p>
        <p>
          5.16 The Vendor shall at all times during the pendency of this
          agreement endeavor to protect and promote the interests of the Firm
          and ensure that third parties rights including intellectual property
          rights are not infringed.
        </p>
        <p>
          5.17 The Vendor shall at all times be responsible for compliance of
          all applicable laws and regulations including but not limited to
          Intellectual Property Rights, Local Sales Tax, Central Sales Tax,
          Service tax, Value added tax, Standards of Weights &amp; Measures
          legislation, Sale of Goods Act, Excise and Import duties, Pharmacy and
          Poisons Act, Advertisement and promotion of medicine guidelines, PPB
          advertising policy etc.
        </p>
        <p>
          5.18 To provide to the Firm copies of any document required by the
          Firm for the purposes of performance of its obligations under this
          agreement within 48 hours of getting a written notice from the Firm.
        </p>
        <p>
          5.19 To seek advance written approval from the Firm, prior to release
          of any promotion/advertisement material, in so far as the same relates
          to services offered pursuant to the terms of this Agreement.
        </p>
        <ol start="6">
          <li>
            <strong>
              {' '}
              Warranties, Representations and Undertakings of the Vendor
            </strong>
          </li>
        </ol>
        <p>The Vendor warrants and represents that</p>
        <p>
          6.1 The signatory to the present agreement is having the right and
          full authority to enter into this Agreement with the Firm and the
          agreement so executed is binding in nature.
        </p>
        <p>
          6.2 All obligations narrated under this Agreement are legal, valid,
          binding and enforceable in law against Vendor.
        </p>
        <p>
          6.3 There are no proceedings pending against the Vendor, which may
          have a material adverse effect on its ability to perform and meet the
          obligations under this Agreement.
        </p>
        <p>
          6.4 That it is an authorized business establishment and hold all the
          requisite permissions, authorities, approvals and sanctions to conduct
          its business and to enter into present agreement with the Firm.
        </p>
        <p>
          6.5 It shall, at all times ensure compliance with all the requirements
          applicable to its business and for the purposes of this agreement
          including but not limited to Intellectual Property Rights, Sales Tax,
          Central Sales Tax, Service tax, Standards of Weights &amp; Measures
          legislation, Sale of Goods Act, Value added tax, Excise and Import
          duties, etc. It further declares and confirm that it has paid and
          shall continue to discharge all its obligations towards statutory
          authorities.
        </p>
        <p>
          6.7 That it has adequate rights under relevant laws including but not
          limited to various Intellectual Property Legislation(s) to enter into
          this Agreement with the Firm and perform the obligations contained
          herein and that it has not violated/ infringed any intellectual
          property rights of any third party.
        </p>
        <p>
          6.7 It shall maintain details of all transaction and mark as complete
          / incomplete as the case may be and shall provide the same to the Firm
          upon demand.
        </p>
        <p>
          <strong>7 Rights of Firm:</strong>
        </p>
        <p>
          7.1 Vendor agrees and acknowledges that the Firm, at all times during
          the continuance of this Agreement, shall have the right to
          remove/block/delete any text, graphic, image(s) uploaded on the online
          store by the Vendor without any prior intimation to Vendor in the
          event the said text, image, graphic is found to be in violation of
          law, breach of any of the terms of this Agreement, terms and
          conditions of afyabook Shopping Website. In such an event, the Firm
          reserve the right to forthwith remove/close the online store of the
          Vendor without any prior intimation or liability to the Vendor.
        </p>
        <p>
          7.2 Appropriate disclaimers and terms of use on afyabook platform
          shall be placed by the Firm.
        </p>
        <p>
          7.3 At any time if the Firm believes that the services are being
          utilized by the Vendor or its Customer in contravention of the terms
          and provisions of this Agreement, Terms and conditions of use of
          afyabook platform, the Firm shall have the right either at its sole
          discretion or upon the receipt of a request from the legal / statutory
          authorities or a court order to discontinue/terminate the said
          service(s) to Customer or the End user as the case may be, forthwith
          remove/block/close the online store of the Vendor and furnish such
          details about the Vendor and/or its customers upon a request received
          from the Legal/ Statutory Authorities or under a Court order.
        </p>
        <p>
          <strong>8 Indemnity</strong>
        </p>
        <p>
          8.1 The Vendor indemnifies and shall hold indemnified the Firm, its
          partners, officers, employees, representatives, agents from and
          against all losses, damages, claims, suits, legal proceedings and
          otherwise howsoever arising from or in connection with any claim
          including but not limited to claim for any infringement of any
          intellectual property rights or any other rights of any third party or
          of law, concerning quality, quantity and any claim in relation to the
          Vendor&rsquo;s product, the breach of any of the Vendor&rsquo;s
          warranties, representations or undertakings or in relation to the
          non-fulfillment of any of its obligations under this Agreement or
          arising out of the Vendor infringing any applicable laws, regulations
          including but not limited to Intellectual Property Rights, Local Sales
          Tax, Central Sales Tax, Service tax, Value Added tax, The Standards of
          Weights &amp; Measures legislation, Excise and Import duties, pharmacy
          and poisons board act etc. . For the purpose of this clause reference
          to the Firm shall also include the Mobile Operators and such other
          agencies through whom the Firm shall make the Online Store available
          to the Customers.
        </p>
        <p>
          8.2 This article shall survive the termination or expiration of this
          Agreement.
        </p>
        <p>
          <strong>9 Limitation of Liability</strong>
        </p>
        <p>
          9.1 The Firm on the basis of representation by the Vendor is marketing
          the products of the Vendor on the afyabook platform to enable Vendor
          to offer its products for sale through the said online shopping
          portal. This representation is the essence of the Contract. It is
          expressly agreed by the vendor that the Firm shall under no
          circumstances be liable or responsible for any loss, injury or damage
          to the Vendor, customer or any other party whomsoever, arising on
          account of any transaction under this Agreement or as a result of the
          Products being in any way damaged, defective, in unfit condition,
          infringing/ violating any laws/ regulations/ intellectual property
          rights of any third party. The Vendor agrees and acknowledges that it
          shall be solely liable for any claims, damages, allegation arising out
          of the Products offered for sale through online shopping portal
          afyabook (including but not limited to quality, quantity, price,
          merchantability, use for a particular purpose, or any other related
          claim) and shall hold the Firm harmless and indemnified against all
          such claims and damages. Further the Firm shall not be liable for any
          claims, damages arising out of any negligence, misconduct or
          misrepresentation by the Vendor or any of its representatives.
        </p>
        <p>
          9.2 The Firm under no circumstances will be liable to the Vendor for
          loss and/or anticipated loss of profits, or for any direct or
          indirect, incidental, consequential, special or exemplary damages
          arising from the subject matter of this Agreement, regardless of the
          type of claim and even if the Vendor has been advised of the
          possibility of such damages, such as, but not limited to loss of
          revenue or anticipated profits or loss business, unless such loss or
          damages is proven by the Vendor to have been deliberately caused by
          the Firm.
        </p>
        <p>
          <strong>10 Termination and effects of Termination</strong>
        </p>
        <p>
          10.1 This Agreement may be terminated by the Firm forthwith in the
          event
        </p>
        <p>
          10.1.1 Vendor commits a material breach of any representation,
          obligations, covenant, warranty or term of this agreement and the same
          is not cured within 30 days after written notice given by the Firm.
        </p>
        <p>10.1.2 If a Petition for insolvency is filed against the Vendor.</p>
        <p>
          10.1.3 If the Vendor is in infringement of the third-party rights
          including intellectual property rights.
        </p>
        <p>
          10.2. This agreement may be terminated without reason by either party
          after serving upon the other, a written notice of 30 days. The
          agreement shall stand terminated after expiry of such period.
        </p>
        <p>10.3 Effect of Termination:</p>
        <p>
          10.3.1 In the event of termination/expiry of this Agreement, the Firm
          shall remove the Links and shall discontinue display of the Products
          on online shopping portal afyabook with immediate effect.
        </p>
        <p>
          10.3.2 Firm shall not be liable for any loss or damages (direct,
          indirect or inconsequential) incurred by the Vendor by virtue of
          termination of this agreement.
        </p>
        <p>
          10.3.3 During the period under notice both the parties shall be bound
          to perform its obligations incurred under this agreement and this
          sub-clause shall survive the termination of this agreement.
        </p>
        <ol start="11">
          <li>
            <strong> Jurisdiction, governing law and ex-parte Orders</strong>
          </li>
        </ol>
        <p>
          11.2 This agreement is subject to exclusive jurisdiction of competent
          Courts of law at Nairobi only.
        </p>
        <p>
          11.2 The laws of Republic of Kenya, as are in force, shall be
          applicable to present agreement.
        </p>
        <p>
          11.3 The Firm is entitled to obtain ex-parte ad- interim injunction
          orders restraining the Vendor to prevent any loss/anticipated loss
          either in material terms or in terms of intellectual property or
          causing damage/loss/harm to reputation/goodwill of the Firm by the
          Vendor, its representatives, associates or assigns.
        </p>
        <ol start="12">
          <li>
            <strong> Notices</strong>
          </li>
        </ol>
        <p>
          12.1 All notices and other communication under this Agreement shall be
          in writing, in English language and shall be caused to be delivered by
          hand or sent by email or courier in each case to the addresses as set
          out at the beginning of this Agreement.
        </p>
        <ol start="13">
          <li>
            <strong> Intellectual Property Rights</strong>
          </li>
        </ol>
        <p>
          13.1 The Vendor expressly authorizes the Firm to use its
          trademarks/copy rights/ designs /logos and other intellectual property
          owned and/or licensed by it for the purpose of reproduction on the
          website and at such other places as the Firm may deem necessary. It is
          expressly agreed and clarified that, except as specified agreed in
          this Agreement, each Party shall retain all right, title and interest
          in their respective trademarks and logos and that nothing contained in
          this Agreement, nor the use of the trademark / logos on the publicity,
          advertising, promotional or other material in relation to the services
          shall be construed as giving to any Party any right, title or interest
          of any nature whatsoever to any of the other Party&rsquo;s trademarks
          and / or logos.
        </p>
        <p>
          <strong>14 Entire Agreement</strong>
        </p>
        <p>
          14.1 This Agreement embodies the entire agreement and understanding of
          the Parties and supersedes any and all other prior and contemporaneous
          agreements, correspondence, arrangements and understandings (whether
          written or oral) between the Parties with respect to its subject
          matter.
        </p>
        <p>
          <strong>15 Assignment</strong>
        </p>
        <p>
          15.1 Neither this Agreement nor any part of it is assignable,
          transferable, sub-licensable, sub-contractable or conveyable by
          Vendor, either by operation of law or otherwise, without the express,
          prior, written consent of the Firm signed by an authorized
          representative of such Party. The Firm is at liberty to refuse such
          consent.
        </p>
        <p>
          <strong>16 Confidentiality:</strong>
        </p>
        <p>
          16.1 The contents of the agreement and any information passed on by
          the Firm to the Vendor is highly confidential in nature and the Vendor
          agrees and undertakes to maintain the confidentiality of the
          information and user/customer data disclosed, generated or made
          available to Vendor under this Agreement. The said information shall
          not be used by the Vendor or its agents, servants, representatives or
          any person acting through or claiming through the Vendor for any
          purpose other than for the performance of its obligations under this
          Agreement. The Vendor agrees that the unauthorized disclosure or use
          of such information would cause irreparable harm and significant
          injury, the degree of which may be difficult to ascertain.
          Accordingly, Vendor agrees that the Firm shall have the right to
          obtain an immediate injunction from any court of law ensuing breach of
          this Agreement and/or disclosure of the Confidential Information. The
          Firm shall also have the right to pursue any other rights or remedies
          available at law or equity for such a breach.
        </p>
        <p>
          <strong>17 Relationship of Parties</strong>
        </p>
        <p>
          17.1 Nothing in this Agreement will be construed as creating a
          relationship of partnership, joint venture, agency or employment
          between the Parties. The Firm shall not be responsible for the acts or
          omissions of the Vendor and Vendor shall not represent the Firm,
          neither has, any power or authority to speak for, represent, bind or
          assume any obligation on behalf of the Firm.
        </p>
        <p>
          <strong>18 Waiver and Amendment</strong>
        </p>
        <p>
          18.1 No waiver of any breach of any provision of this Agreement
          constitutes a waiver of any prior, concurrent or subsequent breach of
          the same or any other provisions and will not be effective unless made
          in writing and signed by an authorized representative of the waiving
          Party.
        </p>
        <p>
          18.2 Except as expressly set out in this Agreement, no amendment is
          binding on the Parties unless it is in writing and signed by a duly
          authorized representative of each of the Parties.
        </p>
        <p>
          <strong>19 Force Majeure</strong>
        </p>
        <p>
          19.1 Neither Party shall be responsible or liable for any delay or
          failure to perform its obligations (other than an obligation to make
          payment) under this Agreement due to unforeseen circumstances or any
          event which is beyond that Party's reasonable control and without its
          fault or negligence, but not limited to, acts of God, war, riots,
          embargoes, strikes, lockouts, acts of any Government authority, delays
          in obtaining licenses or rejection of applications under the Statutes,
          failure of telephone connections or power failure, fire or floods.
        </p>
        <p>
          <strong>20 Two Originals</strong>
        </p>
        <p>
          20.1 This Agreement may be executed upon becoming a vendor in afyabook.
        </p>
      </div>

      {/* <iframe style={{ width: "100%", height: "400px" }} src={`https://view.officeapps.live.com/op/embed.aspx?src=${sfsf}`} frameBorder="0" ></iframe> */}
    </>
  );
}
